import React from "react";
import { Card } from "antd";

const Profession: React.FC = () => {
    return (
        <Card title="Beruf" bordered={false}>
            Ich bin Informatiker und bilde aktuell Lernende aus und vermittle
            ihnen als Coach die grundlegenden Prinzipien der
            Softwareentwicklung.<br />
            Dabei lernen sie diverse Programmiersprachen und Technologien kennen.
        </Card>
    );
};

export default Profession;
