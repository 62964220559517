import React from "react";
import { Layout, Space, Col, Row, Flex, Progress } from "antd";
import Footer from "./components/Footer";
import Profession from "./components/Profession";
import Hobbies from "./components/Hobbies";

const { Content } = Layout;

const App: React.FC = () => {
    return (
        <Layout>
            <Content>
                <Row className="backgroundBanner" align="middle">
                    <Col
                        xs={{ span: 22, offset: 1 }}
                        md={{ span: 11, offset: 0 }}
                        lg={11}
                    >
                        <img
                            src="./img/icon_reto.png"
                            style={{
                                borderRadius: 200,
                                boxShadow:
                                    "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                maxWidth: "100%",
                                float: "right",
                            }}
                            alt="Icon mit Bitmoji-Darstellung als Portrait"
                        ></img>
                    </Col>
                    <Col xs={0} md={2} lg={2}></Col>
                    <Col
                        xs={{ span: 22, offset: 2 }}
                        md={{ span: 11, offset: 0 }}
                        lg={11}
                    >
                        <Flex align="center" justify="left">
                            <div style={{ height: "100%" }}>
                                <h1 className="welcomeText">
                                    Herzlich Willkommen
                                </h1>
                            </div>
                        </Flex>
                    </Col>
                </Row>
                <div className="container">
                    
                
            <Space
                        direction="vertical"
                        size={100}
                        style={{ display: "flex" }}
                    >
                    <Row gutter={[{ xs: 0, sm: 16, md: 24, lg: 32 }, 24]}>
                        <Col xs={24} md={12} lg={12}>
                            <Profession />
                        </Col>
                        <Col xs={24} md={12} lg={12}>
                            <Hobbies />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <h2 style={{ textAlign: 'center' }}>Fähigkeiten</h2>
                            <Flex align="center" justify="space-evenly">
                                <div>
                                    <Progress
                                        type="circle"
                                        percent={96}
                                        format={(percent) => `${percent}%`}
                                    />
                                    <p className="center">JavaScript</p>
                                </div>
                                <div>
                                    <Progress
                                        type="circle"
                                        percent={89}
                                        format={(percent) => `${percent}%`}
                                    />
                                    <p className="center">Frontend</p>
                                </div>
                                <div>
                                    <Progress
                                        type="circle"
                                        percent={85}
                                        format={(percent) => `${percent}%`}
                                    />
                                    <p className="center">Backend</p>
                                </div>
                                <div>
                                    <Progress
                                        type="circle"
                                        percent={76}
                                        format={(percent) => `${percent}%`}
                                    />
                                    <p className="center">DevOps</p>
                                </div>
                            </Flex>
                        </Col>
                    </Row>
                    </Space>
                </div>
            </Content>
            <Footer />
        </Layout>
    );
};

export default App;
