import { Card } from "antd";
import React from "react";

const Hobbies: React.FC = () => {
    return (
        <Card title="Freizeit" bordered={false}>
            Ihn meiner Freizei bin ich leidenschaftlicher Gleitschirmpilot. Ich
            geniesse es, in der Luft zu sein und die Welt von oben zu
            betrachten, ebenfalls bin ich im Vorstand unseres Gleitschirmclubs
            aktiv.
        </Card>
    );
};

export default Hobbies;
